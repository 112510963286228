import Navbar from './Navbar.js'
import Hero from './Hero.js'
import Splash from './Splash.js'
import Projects from './Projects.js'
import Contact from './Contact.js'
import Footer from './Footer.js'
import './App.css';
import '../assets/css/vendor/spinner/react-spinner-loader.css';
import { MutatingDots  } from  'react-loader-spinner';
import React, { useEffect, useRef } from 'react';
import { gsap } from "gsap";


function App() {
  let loading = true;

  const loadingRingRef = useRef();
  const mainContainerRef = useRef();
  const appRef = useRef();

  // wait until DOM has been rendered
  useEffect(() => {
    // gsap.set(mainContainerRef.current, { height: "100vh", overflow: "hidden"});
    let tl = gsap.timeline({repeat: 0, repeatDelay: 1});
    tl.to(loadingRingRef.current, { opacity: 1, duration: 2});
    tl.to(loadingRingRef.current, {  display:"none", duration: 1});
  });

  return (
    <main className="App" ref={appRef}>
      <div id="loadingRing" ref={loadingRingRef}>
      <MutatingDots  ariaLabel="loading-indicator"
        heigth="100"
        width="100"
        color='#83D078'
        secondaryColor='#fff'
        visible={loading}
      /> 
      <Splash></Splash>
      </div>
      <div id="mainContainer" ref={mainContainerRef}>
        <Navbar></Navbar>
        <Hero></Hero>
        <Projects></Projects>
        <Contact></Contact>
        <Footer></Footer>
      </div>
    </main>
  );
}

export default App;
