import './App.css';
import Pulse from 'react-reveal/Pulse';
import { useState } from 'react';
import { send } from 'emailjs-com';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


function Contact(props) {
    let errors = false;
    const [emailObject, setToSend] = useState({
        name: '',
        email: '',
        message: '',
        phone: '',
    });
    const [formErrors, setErrors] = useState({
        name: '',
        email: '',
        message: '',
        phone: '',
    });

    //REGEX
    let isValidPhone = function(userInput) {
        let pattern = /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g;
        if (pattern.test(userInput.trim())){
            return true;
        }
        if (userInput.length > 0){
            return false;
        }
        return true;
    }
    let isValidEmail = function(userInput) {
        let pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (pattern.test(userInput.trim())){
            return true;
        }
        return false;
    }
    let isNotEmpty = function(userInput) {
        // let pattern = /^[a-zA-Z]+$/
        if (userInput.length > 0){
            return true;
        }
        // if (pattern.test(userInput.trim())){
        //     return true;
        // }
        return false;
    }

    const onSubmit = (e) => {
        e.preventDefault();
        setErrors(formErrors => ({name: "", email : "", phone: "", message: "" }));
        if (!isValidPhone(emailObject.phone) || !isNotEmpty(emailObject.name) || !isNotEmpty(emailObject.message) || !isValidEmail(emailObject.email)){
            errors = true;
            if (!isValidPhone(emailObject.phone)){
                setErrors(formErrors => ({ ...formErrors, phone : 'Please enter a valid phone number or leave this field empty.' }));
            }
            if (!isNotEmpty(emailObject.name)) {
                setErrors(formErrors => ({ ...formErrors, name : 'Please do not leave this field empty.' }));
            }
            if (!isNotEmpty(emailObject.message)) {
                console.log('Here/000')
                console.log(emailObject.message)
                setErrors(formErrors => ({ ...formErrors, message : "Please do not leave this field empty." }));
            }
            if (!isValidEmail(emailObject.email)) {
                setErrors(formErrors => ({ ...formErrors, email : "Please enter a valid email." }));
            }

        }
        else{
            setErrors(formErrors => ({ name: "", email : "", phone: "", message: "" }));
            errors = false;
        }
        
        if (!errors){
            send(
                'service_7au8xms',
                'template_eq845kc',
                emailObject,
                'user_5VGGaIDsSSAFqfrRvvumB'
                )
                    .then((response) => {
                    Swal.fire(
                        'Done',
                        'I have received your email and will get back to you shortly',
                        'success'
                        );
                        setToSend(emailObject => ({name: "", email : "", phone: "", message: "" }));
                        // let children = document.getElementById('contactForm').getElementsByClassName('form_input');
                        // for(let child of children){child.value = '';}
                    })
                    .catch((err) => {
                    Swal.fire(
                        'Error',
                        'Something went wrong. Please try again later',
                        'error'
                        );
                });
        }

      };
    
    const handleFormChange = (e) => {
        setToSend({ ...emailObject, [e.target.name]: e.target.value });
    };
    

    return (
        <section id="contact" className="contactSection">
            <Pulse>
                <h2>Like what you see? </h2>
                <h2><span className="highlightedText">Contact me!</span></h2>
                <div className="grid-x">
                <div className="cell large-5 medium-8 small-12 contactFormWrapper">
                    <form id="contactForm" onSubmit={onSubmit}>
                        <label for="contact_name" className="contactFormLabel">Name</label>
                        <input aria-required="true" aria-label='Enter name' value={emailObject.name} onChange={handleFormChange} type="text" className="form_input" id="contact_name" name="name" placeholder="👦 Your name.."required></input>
                        <p className="contactFormError" id="fieldError_name">{formErrors.name}</p>
                        <label for="contact_email" className="contactFormLabel">Email</label>
                        <input aria-required="true" aria-label='Enter email' value={emailObject.email} onChange={handleFormChange} type="text" className="form_input" id="contact_email" name="email" placeholder="📧 Your email.."required></input>
                        <p className="contactFormError" id="fieldError_email">{formErrors.email}</p>
                        <label for="contact_phone" className="contactFormLabel">Phone</label>
                        <input aria-required="true" aria-label='Enter phone' value={emailObject.phone} onChange={handleFormChange} type="text" className="form_input" id="contact_phone" name="phone"  placeholder="📱 Phone number"></input>
                        <p className="contactFormError" id="fieldError_phone">{formErrors.phone}</p>
                        <label for="message" className="contactFormLabel">Message</label>
                        <textarea aria-required="true" aria-label='Enter message'  value={emailObject.message} onChange={handleFormChange} className="form_input" name="message" id="message" placeholder="📪 Message" required/>
                        <p className="contactFormError" id="fieldError_message">{formErrors.message}</p>
                        {/* <textarea id="subject" className="form_input" name="subject" placeholder="📪 Message" style="height:300px"></textarea> */}
                        <input aria-label='Send email' className="formbtn" type="submit" value="Send"></input>
                    </form>
                </div>
                </div>
            </Pulse>
        </section>
    );
}

export default Contact;
