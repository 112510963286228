import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import Zoom from 'react-reveal/Zoom';

function Card(props) {
    return (
        <Zoom>
            <article className="project cell large-3 medium-4 small-12">
                <div className="grid-x grid-margin-x">              
                    <div className="cell projectInfo">
                            <a target="_blank" href={props.projectURL} rel="noreferrer">
                                <img className="cardImage" src={props.imgSrc} alt="Project Screenshot"></img>
                            </a>
                            <div className="projectText">
                                <h5 className="projectTitle">{props.projectName} </h5>
                                <p>{props.projectDescription}</p>
                            </div>
                            <div className="projectLinks">
                                {props.githubURL ? <a target="_blank" aria-label="View on Github" href={props.githubURL} rel="noreferrer"><FontAwesomeIcon className="iconFont" icon={faGithub} /></a> : ""}
                                <a aria-label="View Web Project" target="_blank" href={props.projectURL} rel="noreferrer"><FontAwesomeIcon className="iconFont iconFont_smaller" icon={faLink} /></a>
                            </div>
                    </div>
                    </div>
            </article>
        </Zoom>
    );
}

export default Card;
