import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

function Footer(props) {
    return (
        <footer>
            <p>Jeriel Benavides © 2020 - 2022</p>
            <div className="footerLinks">
                <a aria-label="Check my Github" target="_blank" href="https://github.com/jerieldurhamcollege/" rel="noreferrer"><FontAwesomeIcon className="iconFont" icon={faGithub} /></a>
                <a aria-label="Check my LinkedIn" target="_blank" href="https://www.linkedin.com/in/jerielbenavides/" rel="noreferrer"><FontAwesomeIcon className="iconFont" icon={faLinkedin} /></a>
                <a aria-label="Send me an email" target="_blank" href="mailto:jerielmanuelb@outlook.com" rel="noreferrer"><FontAwesomeIcon className="iconFont" icon={faEnvelope} /></a>
            </div>
        </footer>
    );
}

export default Footer;
