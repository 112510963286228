import './App.css';
// import {  TopBar, TopBarRight,TopBarLeft, Menu,MenuItem,MenuText} from 'react-foundation';
import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

function Navbar() {
    const [toggleMenu, setToggleMenu] = useState(false);
    const toggleNav = () => {
        setToggleMenu(!toggleMenu)
        }
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    useEffect(() => {

    const changeWidth = () => {
        setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', changeWidth)

    return () => {
        window.removeEventListener('resize', changeWidth)
    }

    }, [])


    
    return (
        <nav>
        <div className="top-bar" id="nav-menu">
            <div className="top-bar-left">
                <ul className="dropdown menu" data-dropdown-menu>
                <li className="menu-text">Jeriel Benavides</li>
                </ul>
            </div>
            <div className="top-bar-right">
            {(toggleMenu || screenWidth > 800) && (
                <ul className="menu">
                <li className="navitem"><a aria-label="About" href="#about" onClick={toggleNav}>about</a></li>
                <li className="navitem"><a aria-label="Projects" href="#projects" onClick={toggleNav}>projects</a></li>
                <li className="navitem"><a aria-label="Contact" href="#contact" onClick={toggleNav}>contact</a></li>
                </ul>
            )}

                <button onClick={toggleNav} aria-label="Navbar menu button" className="navbar-btn"><FontAwesomeIcon className="iconFont" icon={faBars} /></button>
            </div>
        </div>
        </nav>
);

}

export default Navbar;
