import './App.css';
import Card from './Card.js';
import madMoviesBanner from '../assets/img/projects/madMoviesBanner.webp';
import signoutBanner from '../assets/img/projects/signoutBanner.webp';
import wat9sBanner from '../assets/img/projects/wat9sBanner.webp';
import emojiBanner from '../assets/img/projects/emojiBanner.webp';
import urlShortenerBanner from '../assets/img/projects/urlShortenerBanner.webp';
import dracmaBanner from '../assets/img/projects/dracmaBanner.webp';
import tradexiqBanner from '../assets/img/projects/tradexiqBanner.webp';
import groceriesBanner from '../assets/img/projects/groceriesBanner.png';
import hctmBanner from '../assets/img/projects/hctmBanner.png';

function Projects() {
    return (
        <section id="projects" className="projectsSection">
            <h2>Some of my <span className="highlightedText">projects</span></h2>
            <div className="grid-x grid-margin-x">
                <Card className="projectCard" projectName="Mad Movies" githubURL="https://github.com/jerieldurhamcollege/madmovies" projectURL = "http://portfolio.jerielbenavides.com/madmovies/" imgSrc = {madMoviesBanner} projectDescription="Mad Movies is a movie database built using JavaScript, Foundations, PHP, and MySQL."></Card>
                <Card className="projectCard" projectName="Equipment Signout" githubURL="https://github.com/jerieldurhamcollege/sign-out-durham" projectURL = "http://portfolio.jerielbenavides.com/signout/" imgSrc = {signoutBanner} projectDescription="Inventory management system built with Vue.js, PHP, and MySQL."></Card>
                <Card className="projectCard" projectName="WAT9S" githubURL="https://github.com/jerieldurhamcollege/wat9s" projectURL = "http://portfolio.jerielbenavides.com/wat9s/" imgSrc = {wat9sBanner} projectDescription="WAT9S is an interactive game built using GSAP and JavaScript."></Card>
                {/*<Card className="projectCard" projectName="Revista Dracma" githubURL={false} projectURL = "http://revistadracma.online/" imgSrc = {dracmaBanner} projectDescription="Wordpress site built for important economy magazine in Nicaragua (Spanish site)"></Card>*/}
                <Card className="projectCard" projectName="TradexIQ" githubURL={false} projectURL = "http://portfolio.jerielbenavides.com/tradexiq/" imgSrc = {tradexiqBanner} projectDescription="TradexIQ is a Wordpress site built for a fictional company to showcase my WordPress skills."></Card>
                {/*<Card className="projectCard" projectName="URL Shortener" githubURL="https://github.com/jerieldurhamcollege/URLShortener" projectURL = "https://url-shortener-20220216.herokuapp.com/" imgSrc = {urlShortenerBanner} projectDescription="URL Shortener service powered by Node.js"></Card>*/}
                <Card className="projectCard" projectName="Jerry's Groceries" githubURL="https://github.com/jerieldurhamcollege/grocery_store" projectURL = "http://portfolio.jerielbenavides.com/easygroceries/dynamic_final/" imgSrc = {groceriesBanner} projectDescription="Jerry's Groceries is an ecommerce site built with PHP, MySQL, and JavaScript"></Card>
                <Card className="projectCard" projectName="Animated Emojis" githubURL="https://github.com/jerieldurhamcollege/gsap_animations" projectURL = "http://portfolio.jerielbenavides.com/animated_emoji/" imgSrc = {emojiBanner} projectDescription="Animated emojis built using GSAP, SVGs, and JavaScript"></Card>
                {/*<Card className="projectCard" projectName="HCTM" githubURL={false} projectURL = "http://www.hctm.tirththakkar.com/" imgSrc = {hctmBanner} projectDescription="HCTM is is a WordPress site that promotes Health Care Technology Management in Canada and was built using Elementor and BBPress."></Card>*/}
            </div>
        </section>
    );
}

export default Projects;
