import './App.css';
import Pulse from 'react-reveal/Pulse';

function Splash() {
    return (
        <section id="splash" className="splashSection">
            <Pulse>
                <h2>Welcome to my </h2>
                <h2><span className="highlightedText">portfolio</span></h2>
                <div className="grid-x">
                <div className="cell large-5 medium-8 small-12">
                    
                </div>
                </div>
            </Pulse>
        </section>
    );
}

export default Splash;
