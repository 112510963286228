import './App.css';
// import mePhoto from '../assets/img/me.jpg';
import mePhoto from '../assets/img/ME_PROF.webp';
// import Fade from 'react-reveal/Fade';
import Pulse from 'react-reveal/Pulse';
import TypeAnimation from 'react-type-animation';

function Hero() {
    return (

            <header id="about" className="">
                <div className="grid-x">
                    <div className="cell large-8 medium-8 small-12 heroText">
                        <p>Hello world! My name is </p>
                        <h1>Jeriel Benavides</h1>
                        <h2>and I am a <TypeAnimation
                            cursor={false}
                            sequence={['web developer.', 1500, 'web designer.', 1500]}
                            wrapper="span"
                            className= 'highlightedText'
                            repeat={Infinity}
                        />
                        </h2>
                        <p>I am a software engineer with a diverse skill set that allows me to design and build elegant and reliable web applications.</p>
                    </div>
                    <div className="cell large-1 medium-1 small-0"></div>
                    <div className="cell large-3 medium-3 heroImageContainer">
                    {/* hide-for-small-only> */}
                        <Pulse>
                        <a aria-label="Portfolio link" target="_blank" href="https://www.linkedin.com/in/jerielbenavides/" rel="noreferrer">
                            <img src={mePhoto} className="mePhoto" alt="Jeriel Benavides" />
                        </a>
                        <a aria-label="Download Resume Link" id="resumeLink" href="/files/JERIEL_BENAVIDES_RESUME.pdf" target="_blank">Download my resume</a>

                        </Pulse>
                    </div>
                </div>
            </header>
    );
}

export default Hero;
